import { viteDomainSplitBaseUrl } from '@/constants/env';
import {
  FF_DSPLIT_ADMIN_PORTAL_REDIRECT,
  FF_DSPLIT_PATIENT_CUSTOMER_PORTAL_REDIRECT,
  FF_DSPLIT_PHARMACIST_PORTAL_REDIRECT,
  FF_DSPLIT_REDIRECT_ALL
} from '@/constants/featureFlags';
import USER_ROLES from '@/constants/userRoles';
import type { LoginUser } from '@/types';

const redirectUser = (baseUrl: string, queryParams: string) => {
  if (baseUrl === undefined) {
    console.error("can't find the base url in your env variables");
    return false;
  }
  window.location.replace(`${baseUrl}/login?${queryParams}`);
  return true;
};

export const handleDsplitRedirect = (user: LoginUser, flags: any[string]) => {
  const queryParams = new URLSearchParams([
    ['uid', user.id.toString()],
    ['target_url', location.pathname]
  ]).toString();
  let applyRedirect = false;
  if (
    flags[FF_DSPLIT_ADMIN_PORTAL_REDIRECT] ||
    (flags[FF_DSPLIT_REDIRECT_ALL] && user.role_id === (USER_ROLES.admin || USER_ROLES.superAdmin))
  ) {
    applyRedirect = redirectUser(viteDomainSplitBaseUrl('admin'), queryParams);
    return applyRedirect;
  }
  if (
    flags[FF_DSPLIT_PATIENT_CUSTOMER_PORTAL_REDIRECT] ||
    (flags[FF_DSPLIT_REDIRECT_ALL] && user.role_id === USER_ROLES.patient)
  ) {
    const baseUrl =
      user.role_id === USER_ROLES.patient ? viteDomainSplitBaseUrl('patient') : viteDomainSplitBaseUrl('customer');
    applyRedirect = redirectUser(baseUrl, queryParams);
    return applyRedirect;
  }
  if (
    flags[FF_DSPLIT_PHARMACIST_PORTAL_REDIRECT] ||
    (flags[FF_DSPLIT_REDIRECT_ALL] && user.role_id === USER_ROLES.pharmacist)
  ) {
    applyRedirect = redirectUser(viteDomainSplitBaseUrl('pharmacist'), queryParams);
    return applyRedirect;
  }

  return false;
};
