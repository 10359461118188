import FilterByOrderStatusSelect from '@/components/common/FilterByOrderStatusSelect';
import type { PharmacyFilterOption } from '@/components/common/FilterOrderByPharmacySelect';
import FilterOrderByPharmacySelect from '@/components/common/FilterOrderByPharmacySelect';
import TableSearchInput from '@/components/common/TableSearchInput';
import { useOrders } from '@/context/orders/Orders';
import AuthService from '@/services/authentication.service';
import type { OrderStatus } from '@/types';
import { Stack } from '@mui/material';
import debounce from 'awesome-debounce-promise';

export function OrdersFilters() {
  const { filter, onFilterChange, statusFilters, selectStatusFilters, selectedPharmacies, selectPharmacies } =
    useOrders();

  const handleStatusFiltersSelect = (selectedOrderStatuses: OrderStatus[]) => {
    selectStatusFilters(selectedOrderStatuses);
  };

  const handlePharmacySelect = (selectedOptions: PharmacyFilterOption[] | null) => {
    selectPharmacies(selectedOptions ?? []);
  };

  const handleSearch = debounce((value: string) => {
    onFilterChange(value);
  }, 500);

  return (
    <Stack direction="row" spacing={2}>
      <TableSearchInput
        label="Search"
        placeholder="MPRS, MPAT, ALPAT, Name, Doctor"
        value={filter}
        onChange={handleSearch}
      />
      {AuthService.isSuperAdmin() && (
        <FilterOrderByPharmacySelect onSelect={handlePharmacySelect} selectedPharmacies={selectedPharmacies} />
      )}
      <FilterByOrderStatusSelect onSelect={handleStatusFiltersSelect} selectedStatuses={statusFilters} />
    </Stack>
  );
}
