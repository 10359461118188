import TableSearchInput from '@/components/common/TableSearchInput';
import { GP_FILTERS, useDoctors } from '@/context/doctors/Doctors';
import { Box, MenuItem, Select, styled } from '@mui/material';
import type { SelectChangeEvent } from '@mui/material/Select';

const ActionsContainer = styled(Box)(({ theme }) => ({
  display: 'flex',
  flexDirection: 'column',
  gap: theme.spacing(2),
  position: 'relative',
  alignItems: 'flex-end',
  [theme.breakpoints.up('sm')]: {
    flexDirection: 'row',
    justifyContent: 'space-between',
    alignItems: 'center'
  }
}));

function DoctorsActions() {
  const { gpFilter, setGpFilter, filter, onFilterChange } = useDoctors();

  const handleGpFilterChange = (event: SelectChangeEvent) => {
    setGpFilter(event.target.value as GP_FILTERS);
  };

  return (
    <ActionsContainer>
      <TableSearchInput value={filter} onChange={onFilterChange} />
      <Select
        size="small"
        value={gpFilter}
        onChange={handleGpFilterChange}
        sx={{ minWidth: 208 }}
        data-testid="gp-filter"
      >
        <MenuItem value={GP_FILTERS.All}>All</MenuItem>
        <MenuItem value={GP_FILTERS['General prescriber']}>General prescriber</MenuItem>
        <MenuItem value={GP_FILTERS['Authorised prescriber']}>Authorised prescriber</MenuItem>
      </Select>
    </ActionsContainer>
  );
}

export default DoctorsActions;
