import { useOrderFormMeta } from '@/context/order/OrderFormMeta';
import { getOrderCreationReasons } from '@/services/data.service';
import { Box, FormControl, InputLabel, MenuItem, Select, Skeleton, styled, Typography } from '@mui/material';
import { useEffect, useState } from 'react';
import { Controller, useFormContext } from 'react-hook-form';

interface OrderCreationReason {
  id: number;
  reason: string;
}

interface GetOrderCreationReasonsResponse {
  data: OrderCreationReason[];
}

const Container = styled(Box)(({ theme }) => ({
  marginBottom: theme.spacing(4)
}));

const Header = styled(Typography)(({ theme }) => ({
  marginBottom: theme.spacing(4)
}));

export const OrderDetailsReason = () => {
  const { isLoading, isNewOrder, isEditing, isAdminInititatedOrder } = useOrderFormMeta();
  const [reasonList, setReasonList] = useState<OrderCreationReason[]>([]);

  const { control } = useFormContext();

  useEffect(() => {
    getOrderCreationReasons().then((response: GetOrderCreationReasonsResponse) => {
      setReasonList((response.data ?? []).sort((a, b) => a.reason.localeCompare(b.reason)));
    });
  }, []);

  if (isLoading) {
    return (
      <>
        <Typography variant="body1" marginBottom={4} data-testid="loading">
          Manual Order Reason
        </Typography>
        <Skeleton width="100%" height={50} variant="rectangular" />
      </>
    );
  }

  if (!isAdminInititatedOrder || isNewOrder) {
    return null;
  }

  return (
    <Container>
      <Header variant="body1">Manual Order Reason</Header>
      <FormControl fullWidth>
        <Controller
          name="adminOrderCreationReasonId"
          control={control}
          rules={{ required: 'A reason is required' }}
          render={({ field, fieldState: { error } }) => (
            <>
              <InputLabel id="demo-multiple-name-label" shrink={true}>
                Select a reason *
              </InputLabel>
              <Select
                labelId="order-reason-label"
                id="order-reason"
                data-testid="order-reason"
                label="Select a reason *"
                inputProps={{
                  helperText: error,
                  error: !!error
                }}
                readOnly={(!isNewOrder && !isEditing) || reasonList.length === 0}
                {...field}
              >
                {reasonList.map((reason: OrderCreationReason) => (
                  <MenuItem key={reason.id} value={reason.id}>
                    {reason.reason}
                  </MenuItem>
                ))}
              </Select>
            </>
          )}
        />
      </FormControl>
    </Container>
  );
};

export default OrderDetailsReason;
