import PatientDiscountModal from '@/components/admin/patients/PatientDiscountModal';
import { FF_DISABLE_ADMIN_DISCOUNT_CREATION, FF_SHOPIFY_DISCOUNT_SUPER_ADMINS } from '@/constants/featureFlags';
import { useGetPatient } from '@/hooks/admin/useGetPatient';
import useFeatureFlags from '@/hooks/useFeatureFlags';
import AuthService from '@/services/authentication.service';
import { DiscountOutlined } from '@mui/icons-material';
import { Box, Button, styled } from '@mui/material';
import { useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';

const Container = styled(Box)(({ theme }) => ({
  display: 'flex',
  flexDirection: 'row',
  alignItems: 'center',
  gap: theme.spacing(2)
}));

const PatientActions = () => {
  const navigate = useNavigate();
  const { id } = useParams();
  const { data: patientData } = useGetPatient(id ?? '');
  const { flags } = useFeatureFlags();
  const [showModal, setShowModal] = useState({
    create: false,
    edit: false
  });

  const shopifyCustomerId = patientData?.data?.shopify_customer_id;
  const isPatientDischarged = patientData?.data?.is_discharge || false;

  const isDiscountCreationDisabled = flags[FF_DISABLE_ADMIN_DISCOUNT_CREATION] || isPatientDischarged;
  const canAddDiscount = AuthService.isSuperAdmin() || flags[FF_SHOPIFY_DISCOUNT_SUPER_ADMINS];

  const handleAddDiscountClick = () => {
    return setShowModal({
      edit: false,
      create: true
    });
  };

  const onboardOrder = () => {
    navigate(`/order-patient/${id}`);
  };

  const handlePatientDiscountModal = () => {
    setShowModal({
      create: false,
      edit: false
    });
  };

  return (
    <Container>
      {canAddDiscount && !isDiscountCreationDisabled && (
        <Button
          component="button"
          variant="outlined"
          size="large"
          startIcon={<DiscountOutlined />}
          onClick={handleAddDiscountClick}
          data-testid="add-discount-button"
        >
          Add Discount
        </Button>
      )}
      {!isPatientDischarged && (
        <Button
          component="button"
          variant="contained"
          onClick={onboardOrder}
          size="large"
          data-testid="new-patient-order-button"
        >
          New Patient Order
        </Button>
      )}

      <PatientDiscountModal
        shopifyCustomerId={shopifyCustomerId}
        show={showModal.create ? showModal.create : showModal.edit}
        close={handlePatientDiscountModal}
        data-testid="patient-discount-modal"
      />
    </Container>
  );
};

export { PatientActions };
