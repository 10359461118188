import InfoIcon from '@mui/icons-material/Info';
import LocalPharmacyIcon from '@mui/icons-material/LocalPharmacy';
import WarehouseIcon from '@mui/icons-material/Warehouse';
import { Box, Divider, IconButton, Stack, styled, Tooltip, Typography } from '@mui/material';
import { DateTime } from 'luxon';

import settings from '@/constants/constants';
import { useUnableToDispenseModal } from '@/context/orders/OrdersModals';
import type { Order } from '@/services/data.service';

type OrderStatusTableCellProps = {
  order: Order;
};

const Container = styled(Box)(({ theme }) => ({
  maxWidth: theme.spacing(24),
  [theme.breakpoints.up('lg')]: {
    maxWidth: theme.spacing(48)
  }
}));

const StatusContainer = styled(Stack)(({ theme }) => ({
  display: 'flex',
  alignItems: 'center',
  color: theme.palette.grey[600]
}));

function OrderStatusTableCell({ order }: OrderStatusTableCellProps) {
  const { orderStatus, modified_date, order_status_id, shipped_date, dispensed_date } = order;
  const { showUnableToDispenseModal } = useUnableToDispenseModal();

  const handleUnableToDispenseInfoClick = () => {
    showUnableToDispenseModal(order.id);
  };

  const formatDate = (dateString?: string) => {
    if (!dateString) {
      return '';
    }
    const parsedDate = DateTime.fromISO(dateString).toLocal();
    return parsedDate.isValid ? parsedDate.toFormat('dd-MMM-yyyy') : '';
  };

  const parsedModifiedDate = formatDate(modified_date);
  const parsedShippedDate = formatDate(shipped_date);
  const parsedDispensedDate = formatDate(dispensed_date);

  if (!order_status_id) {
    return <Container />;
  }

  if (order_status_id === settings.orderStatus.UNABLE_TO_DISPENSE) {
    return (
      <Container>
        <Stack gap={1}>
          <Stack direction="row" alignItems="center">
            <Box>
              <IconButton size="small" onClick={handleUnableToDispenseInfoClick}>
                <InfoIcon />
              </IconButton>
            </Box>
            <Box>Unable to dispense</Box>
          </Stack>
        </Stack>
      </Container>
    );
  }

  return (
    <Container>
      <Stack gap={2} display="flex" alignItems="center" justifyContent="space-between">
        <Box>
          <Tooltip title="Current order status">
            <span>{orderStatus}</span>
          </Tooltip>
          {[settings.orderStatus.CANCELLED, settings.orderStatus.REFUNDED].includes(order_status_id) && (
            <Box>{parsedModifiedDate}</Box>
          )}
        </Box>
        {parsedShippedDate && (
          <Box width="100%">
            <Divider />
          </Box>
        )}
        {parsedShippedDate && (
          <Box>
            <StatusContainer direction="row" gap={1}>
              <Tooltip title="Dispatched to Pharmacy">
                <WarehouseIcon fontSize="small" />
              </Tooltip>
              <Typography variant="caption">{parsedShippedDate}</Typography>
            </StatusContainer>
          </Box>
        )}
        {parsedDispensedDate && (
          <StatusContainer direction="row" gap={1}>
            <Tooltip title="Dispensed from Pharmacy">
              <LocalPharmacyIcon fontSize="small" />
            </Tooltip>
            <Typography variant="caption">{parsedDispensedDate}</Typography>
          </StatusContainer>
        )}
      </Stack>
    </Container>
  );
}

export default OrderStatusTableCell;
