import { AdminPageSection } from '@/components/layout/AdminPageSection';
import settings from '@/constants/constants';
import { useGetPatient } from '@/hooks/admin/useGetPatient';
import AuthService from '@/services/authentication.service';
import type { PatientDiscount } from '@/types';
import { Table } from '@montugroup/design-system';
import { Delete, Edit } from '@mui/icons-material';
import { Chip, IconButton, styled } from '@mui/material';
import type { ColumnDef } from '@tanstack/react-table';
import { DateTime } from 'luxon';
import { useParams } from 'react-router-dom';

const redirectToShopify = (shopifyDiscountId: string) => {
  const { shopifyDiscountsURL, shopifyShippingDiscountAppURL } = settings;
  window.open(
    `${shopifyDiscountsURL}/${shopifyShippingDiscountAppURL}/${shopifyDiscountId}`,
    '_blank',
    'noopener noreferrer'
  );
};

const Actions = styled(IconButton)(({ theme }) => ({
  display: 'flex',
  gap: theme.spacing(1),
  alignItems: 'center'
}));

const columns: ColumnDef<PatientDiscount>[] = [
  {
    accessorKey: 'id',
    header: 'id',
    enableSorting: true
  },

  {
    accessorKey: 'DiscountType.name',
    header: 'Discount Type',
    enableSorting: false
  },
  {
    accessorKey: 'DiscountReason.reason',
    header: 'Reason',
    enableSorting: false
  },
  {
    accessorKey: 'discount_percentage',
    header: 'Discount Amount',
    enableSorting: false,
    cell: ({ row }) =>
      row.original.discount_percentage ? `${row.original.discount_percentage}%` : `$${row.original.discount_amount}`
  },
  {
    accessorKey: 'Product',
    header: 'Product Name',
    enableSorting: false,
    cell: ({ row }) => (row.original.Product ? row.original.Product?.name : '-')
  },
  {
    accessorKey: 'start_date',
    header: 'Start Date',
    enableSorting: false,
    cell: ({ row }) => DateTime.fromISO(row.original.start_date).toFormat('dd-MMM-yyyy')
  },
  {
    accessorKey: 'end_date',
    header: 'End Date',
    enableSorting: false,
    cell: ({ row }) => DateTime.fromISO(row.original.end_date).toFormat('dd-MMM-yyyy')
  },
  {
    accessorKey: 'DiscountType.status',
    header: 'Status',
    enableSorting: false,
    cell: ({ row }) => (row.original.active ? <Chip label="Active" color="success" /> : <Chip label="Inactive" />)
  },
  {
    id: 'actions',
    header: 'Actions',
    enableSorting: false,
    cell: ({ row }) =>
      row.original.active && (
        <Actions
          onClick={() => redirectToShopify(`${row.original.PatientDiscountsShopify[0].shopify_discount_id}`) as any}
        >
          <Edit aria-label="edit" color="action" sx={{ cursor: 'pointer' }} data-testid="edit" />
          <Delete aria-label="delete" color="action" sx={{ cursor: 'pointer' }} data-testid="delete" />
        </Actions>
      )
  }
];

const PatientDiscounts = () => {
  const { id } = useParams();

  const { data: patientData } = useGetPatient(id ?? '');
  const patientDiscounts = patientData?.data?.patientDiscounts ?? [];

  if (!patientDiscounts.length) {
    return null;
  }

  if (!AuthService.isAdmin()) {
    return null;
  }
  return (
    <AdminPageSection
      title="Patient Discounts"
      subheading="The discounts listed here are either active or inactive, this does not reflect their usage status."
    >
      <Table
        data={patientDiscounts}
        columns={columns}
        columnVisibility={{ id: false }}
        hasRowBackgroundColor={false}
        elevation={0}
      />
    </AdminPageSection>
  );
};

export default PatientDiscounts;
