import settings from '@/constants/constants';
import { FF_ENABLE_PATIENT_PROFILE_UPLIFT } from '@/constants/featureFlags';
import { useOrderFormMeta } from '@/context/order/OrderFormMeta';
import useFeatureFlags from '@/hooks/useFeatureFlags';
import { AuthService } from '@/services/authentication.service';
import generateTrackingLink from '@/utils/patient/trackingLink';
import { Box, InputAdornment, Link as MUILink, Skeleton, TextField, styled } from '@mui/material';
import { Controller, useFormContext } from 'react-hook-form';
import { Link } from 'react-router-dom';

const Container = styled(Box)(({ theme }) => ({
  marginBottom: theme.spacing(4)
}));

// Temporary - once montu theme has been updated to handle readOnly textfields this should be removed
const ReadOnlyTextField = styled(TextField)(({ theme }) => ({
  '& .MuiInputAdornment-root': {
    backgroundColor: 'var(--montu-palette-_components-input-filled-enabledFill)',
    padding: '27px 0px',
    margin: 0,
    marginLeft: '-14px',
    borderTopLeftRadius: theme.shape.borderRadius + 'px',
    borderBottomLeftRadius: theme.shape.borderRadius + 'px',
    '& :first-child': {
      marginLeft: '14px'
    }
  }
}));

const TitleStyle = styled(Box)(({ theme }) => ({
  margin: theme.spacing(4, 0)
}));

const InputStyle = styled(Box)(({ theme }) => ({
  display: 'flex',
  flexDirection: 'row',
  gap: theme.spacing(2)
}));

export function PatientOrderDetails() {
  const { flags } = useFeatureFlags();
  const { shopifyDiscountsURL } = settings;

  const { error, isLoading, isNewOrder } = useOrderFormMeta();

  const { control } = useFormContext();

  if (isLoading) {
    return (
      <Box data-testid="loading">
        <TitleStyle>Patient Details</TitleStyle>
        <InputStyle>
          <Skeleton width="50%" height={50} variant="rectangular" />
          <Skeleton width="50%" height={50} variant="rectangular" />
        </InputStyle>

        <TitleStyle>Order Details</TitleStyle>
        <InputStyle>
          <Skeleton width="25%" height={50} variant="rectangular" />
          <Skeleton width="25%" height={50} variant="rectangular" />
          <Skeleton width="25%" height={50} variant="rectangular" />
          <Skeleton width="25%" height={50} variant="rectangular" />
        </InputStyle>
      </Box>
    );
  }

  //   NOTE: Need to update once we get error state design
  if (error) {
    return <div>Error: {error.message}</div>;
  }

  return (
    <Container>
      <TitleStyle>Patient Details</TitleStyle>
      <InputStyle>
        <Controller
          name="patient"
          control={control}
          render={({ field: { value } }) => (
            <ReadOnlyTextField
              label="Patient ID"
              fullWidth
              InputProps={{
                readOnly: true,
                startAdornment: (
                  <InputAdornment position="start">
                    {value.code ? (
                      <MUILink
                        component={Link}
                        to={`/${flags[FF_ENABLE_PATIENT_PROFILE_UPLIFT] ? 'patient' : 'onboard'}/${value.id}`}
                      >
                        {value.code}
                      </MUILink>
                    ) : (
                      '-'
                    )}
                  </InputAdornment>
                )
              }}
              InputLabelProps={{ shrink: true }}
            />
          )}
        />

        <Controller
          name="patient.name"
          control={control}
          render={({ field: { value } }) => (
            <ReadOnlyTextField
              label="Patient Full Name"
              fullWidth
              InputLabelProps={{ shrink: true }}
              value={value}
              InputProps={{
                readOnly: true
              }}
            />
          )}
        />
      </InputStyle>
      {AuthService.isAdmin() && !isNewOrder && (
        <>
          <TitleStyle>Order Details</TitleStyle>
          <InputStyle>
            <Controller
              name="paymentStatus"
              control={control}
              render={({ field: { value } }) => (
                <ReadOnlyTextField
                  label="Payment Status"
                  fullWidth
                  InputLabelProps={{ shrink: true }}
                  value={value}
                  InputProps={{
                    readOnly: true
                  }}
                />
              )}
            />

            <Controller
              name="shopifyOrderConfirmationNumber"
              control={control}
              render={({ field: { value } }) => (
                <ReadOnlyTextField
                  label="Shopify Order Confirmation #"
                  fullWidth
                  InputLabelProps={{ shrink: true }}
                  InputProps={{
                    readOnly: true,
                    startAdornment: (
                      <InputAdornment position="start">
                        {value ? (
                          <MUILink
                            component={Link}
                            to={`${shopifyDiscountsURL}/orders?query=${value}`}
                            target="_blank"
                            rel="noopener noreferrer"
                          >
                            {value}
                          </MUILink>
                        ) : (
                          '-'
                        )}
                      </InputAdornment>
                    )
                  }}
                />
              )}
            />

            <Controller
              name="consignmentId"
              control={control}
              render={({ field: { value } }) => (
                <ReadOnlyTextField
                  label="Consignment ID"
                  fullWidth
                  InputLabelProps={{ shrink: true }}
                  value={value}
                  InputProps={{
                    readOnly: true
                  }}
                />
              )}
            />

            <Controller
              name="shipment"
              control={control}
              render={({ field: { value } }) => (
                <ReadOnlyTextField
                  label="Tracking ID"
                  fullWidth
                  InputLabelProps={{ shrink: true }}
                  InputProps={{
                    readOnly: true,
                    startAdornment: (
                      <InputAdornment position="start">
                        {value.carrier && value.trackingId ? (
                          <MUILink
                            component={Link}
                            to={generateTrackingLink(value.carrier ?? '', value.trackingId ?? '') as any}
                          >
                            {value?.trackingId}
                          </MUILink>
                        ) : (
                          '-'
                        )}
                      </InputAdornment>
                    )
                  }}
                />
              )}
            />
          </InputStyle>
        </>
      )}
    </Container>
  );
}

export default PatientOrderDetails;
