import { AdminPageSection } from '@/components/layout/AdminPageSection';
import Note from '@/components/note/Note';
import { useOrderFormMeta } from '@/context/order/OrderFormMeta';
import AuthService from '@/services/authentication.service';
import { Skeleton } from '@mui/material';
import { useState } from 'react';
import { useFieldArray, useFormContext } from 'react-hook-form';

export const OrderNote = () => {
  const [isAddingNote, setIsAddingNote] = useState(false);
  const { isLoading, isNewOrder, isEditing } = useOrderFormMeta();

  const { control } = useFormContext();

  const { fields, append } = useFieldArray({
    control,
    name: 'notes'
  });

  const canWeAddNote = isAddingNote && (!isNewOrder || !isEditing);

  const onNoteCancel = () => {
    setIsAddingNote(false);
  };

  const onNoteSubmit = (data: { note: string }) => {
    const newNote = { id: fields.length + 1, note: data.note };
    append(newNote);
    setIsAddingNote(false);
  };

  if (isLoading) {
    return (
      <AdminPageSection
        title="Order Notes"
        Actions={<Skeleton width={200} height={38} variant="rectangular" data-testid="loading" />}
      >
        <Skeleton width="100%" height={100} variant="rectangular" />
      </AdminPageSection>
    );
  }

  return (
    AuthService.isAdmin() && (
      <Note
        key={isAddingNote ? 'adding' : 'viewing'}
        title="Order Notes"
        isAddingNote={canWeAddNote}
        setIsAddingNote={setIsAddingNote}
        onNoteSubmit={onNoteSubmit}
        onNoteCancel={onNoteCancel}
        notes={fields}
        enableAuthor={false}
        enableDate={false}
        canShowPagination={false}
        label={'Creating a new order note'}
      />
    )
  );
};

export default OrderNote;
