import CopyToClipboard from '@/components/buttons/CopyToClipboard';
import ListTableCell from '@/components/common/Table/ListTableCell';
import { AdminPageSection } from '@/components/layout/AdminPageSection';
import { useGetPatient } from '@/hooks/admin/useGetPatient';
import { getPrescriptionUrl } from '@/services/data.service';
import type { PatientPrescription } from '@/types';
import { formatISODateHelper } from '@/utils/helpers';
import { dateColumnSort } from '@/utils/table';
import { PaginationVariant, Table, toast } from '@montugroup/design-system';
import { Box, Chip, Link } from '@mui/material';
import type { ColumnDef } from '@tanstack/react-table';
import { useParams } from 'react-router-dom';

const openPrescription = async (key: string) => {
  const response = await getPrescriptionUrl(key);
  if (!response || !response.data) {
    toast.warn('Prescription not found');
    return;
  }
  window.open(response.data);
};

// TODO: Need to updated chip color as per Figma once available
const chipColors = (status: string) => {
  switch (status) {
    case 'Active':
      return 'success';
    case 'Deleted':
      return 'error';
    case 'Cancelled':
      return 'error';
    default:
      return 'primary';
  }
};

const columns: ColumnDef<PatientPrescription>[] = [
  {
    accessorKey: 'url',
    header: 'Prescription ID',
    enableSorting: true,
    cell: ({ row }) => (
      <>
        <Link component="button" variant="body2" onClick={() => openPrescription(row.original.url)}>
          {row.original.orderCode}
        </Link>
        <CopyToClipboard textToCopy={row.original.orderCode} buttonVariant="icon" size="small" />
      </>
    )
  },
  {
    accessorKey: 'productName',
    header: 'Medication',
    enableSorting: true,
    cell: ({ row }) => (
      <ListTableCell
        items={row.original.productName.map((name, index) => ({ key: `medication-${name}-${index}`, value: name }))}
      />
    )
  },
  {
    accessorKey: 'orderDate',
    header: 'Created Date',
    enableSorting: true,
    sortingFn: (rowA, rowB) => dateColumnSort(rowA.original.orderDate, rowB.original.orderDate),
    cell: ({ row }) => (
      <Box minWidth={80}>{row.original.orderDate ? formatISODateHelper(row.original.orderDate) : '-'}</Box>
    )
  },
  {
    accessorKey: 'expiryDate',
    header: 'Expiry Date',
    enableSorting: true,
    sortingFn: (rowA, rowB) => dateColumnSort(rowA.original.expiryDate, rowB.original.expiryDate),
    cell: ({ row }) => (
      <Box minWidth={80}>{row.original.expiryDate ? formatISODateHelper(row.original.expiryDate) : '-'}</Box>
    )
  },

  {
    accessorKey: 'doctorName',
    header: 'Doctor',
    enableSorting: true
  },
  {
    accessorKey: 'quantity',
    header: 'Qty',
    enableSorting: true,
    cell: ({ row }) => (
      <ListTableCell
        items={row.original.quantity.map((qty, index) => ({ key: `quantity-${qty}-${index}`, value: qty }))}
      />
    )
  },
  {
    accessorKey: 'repeats',
    header: 'Rep',
    enableSorting: true,
    cell: ({ row }) => (
      <ListTableCell
        items={row.original.repeats.map((repeat, index) => ({ key: `repeat-${repeat}-${index}`, value: repeat }))}
      />
    )
  },
  {
    accessorKey: 'status',
    header: 'Status',
    enableSorting: true,
    cell: ({ row }) => <Chip color={chipColors(row.original.status)} label={row.original.status} />
  }
];
const DEFAULT_PAGE_SIZE = 5;
const ROWS_PER_PAGE = [5, 10, 20, 50];
export function PatientPrescriptions() {
  const { id } = useParams();

  const { data: patientData } = useGetPatient(id ?? '');
  const patientPrescriptions = patientData?.data.prescriptions ?? [];

  if (!patientPrescriptions.length) {
    return null;
  }

  return (
    <AdminPageSection title="All Prescriptions">
      <Table
        data={patientPrescriptions}
        columns={columns}
        hasRowBackgroundColor={false}
        showPagination={patientPrescriptions.length > DEFAULT_PAGE_SIZE}
        pageSize={DEFAULT_PAGE_SIZE}
        initialSort={[{ id: 'orderDate', desc: true }]}
        manualPagination
        paginationVariant={PaginationVariant.VARIABLE_PAGE_SIZE}
        total={patientPrescriptions.length}
        pageSizeOptions={ROWS_PER_PAGE}
        elevation={0}
      />
    </AdminPageSection>
  );
}
