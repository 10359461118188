import MedicationPrescribedTable from '@/components/admin/patient-profile/MedicationPrescribedTable';
import { PatientActions } from '@/components/admin/patient-profile/PatientActions';
import { PatientApprovals } from '@/components/admin/patient-profile/PatientApprovals';
import { PatientDetails } from '@/components/admin/patient-profile/PatientDetails';
import PatientDiscounts from '@/components/admin/patient-profile/PatientDiscounts';
import { PatientNote } from '@/components/admin/patient-profile/PatientNote';
import { PatientPrescriptions } from '@/components/admin/patient-profile/PatientPrescriptions';
import { chipColors } from '@/components/admin/viewPatients/PatientListTable';
import AdminPageLayout from '@/components/layout/AdminPageLayout';
import { useGetPatient } from '@/hooks/admin/useGetPatient';
import { BrandThemeProvider, montu } from '@montugroup/themes';
import { Box, Chip, Skeleton, Stack, styled } from '@mui/material';
import { Navigate, useLocation, useParams } from 'react-router-dom';

const ChipContainer = styled(Box)(({ theme }) => ({
  display: 'inline-flex',
  alignItems: 'center',
  marginRight: theme.spacing(2)
}));

export function PatientProfile() {
  const { pathname } = useLocation();
  const { id } = useParams();

  const { data: patientData } = useGetPatient(id ?? '');

  const PatientStatus = () => {
    if (!patientData?.data?.patientStatus) {
      return null;
    }
    return patientData.data.patientStatus.map((status: string) => (
      <ChipContainer key={status}>
        <Chip label={status} sx={chipColors(status)} />
      </ChipContainer>
    ));
  };

  if (pathname.startsWith('/onboard/')) {
    return <Navigate to={`/patient/${id}`} replace />;
  }

  return (
    <BrandThemeProvider theme={montu}>
      <AdminPageLayout
        fullWidth={false}
        title="Patient Profile"
        TitleAdornment={<PatientStatus />}
        subtitle={!patientData ? <Skeleton width={150} height={20} /> : patientData?.data.patient_code}
        Actions={!patientData ? <Skeleton width={200} height={38} variant="rectangular" /> : <PatientActions />}
      >
        <Stack gap={6}>
          <PatientDetails />
          <MedicationPrescribedTable />
          <PatientPrescriptions />
          <PatientDiscounts />
          <PatientApprovals />
          <PatientNote />
        </Stack>
      </AdminPageLayout>
    </BrandThemeProvider>
  );
}
