import { toolbarClasses } from '@mui/material';
import { experimental_extendTheme as extendTheme } from '@mui/material/styles';
/* TODO: Confirm the usage of the below block */
declare module '@mui/material/styles' {
  interface BreakpointOverrides {
    xs: true;
    sm: true;
    md: true;
    lg: true;
    xl: true;
  }
}

const palette = {
  palette: {
    primary: {
      main: '#47BC8F', // green
      contrastText: '#FFF'
    },
    secondary: {
      main: '#ee981c',
      contrastText: '#FFF'
    },
    info: {
      main: '#e5f6fd',
      dark: '#0288D1'
    }
  }
};

const theme = extendTheme({
  cssVarPrefix: 'legacy-theme',
  spacing: (factor: number) => `${0.25 * factor}rem`,
  typography: {
    fontFamily: '"Sofia Pro","Roboto","Helvetica","Arial",sans-serif',
    fontWeightBold: 800,
    button: {
      textTransform: 'none',
      fontSize: '1rem'
    }
  },
  breakpoints: {
    values: {
      xs: 0,
      sm: 640,
      md: 768,
      lg: 1024,
      xl: 1280
    }
  },
  colorSchemes: {
    light: palette,
    dark: palette
  },
  components: {
    MuiButton: {
      defaultProps: {
        disableRipple: true,
        disableElevation: true,
        disableFocusRipple: true,
        variant: 'contained'
      },
      styleOverrides: {
        root: () => ({
          fontSize: '1.1rem'
        })
      }
    },
    MuiCard: {
      styleOverrides: {
        root: {
          borderRadius: '0.325rem'
        }
      }
    },
    MuiTooltip: {
      styleOverrides: {
        tooltip: {
          fontSize: '1.1rem'
        }
      }
    }
  }
});

/** @deprecated - Use [theme.breakpoints.up('<breakpoint>')] within styled instead */
export const mq = (n: keyof typeof theme.breakpoints.values) => `@media (min-width: ${theme.breakpoints.values[n]}px)`;

const alternaleafPalette = {
  palette: {
    primary: {
      main: '#300431', // purple
      light: '#8c0c8f',
      contrastText: '#FFF'
    },
    secondary: {
      main: '#ff5d2b',
      dark: '#c42e00',
      contrastText: '#FFF'
    },
    warning: {
      main: '#ff0000'
    },
    background: {
      default: '#ffffff'
    }
  }
};

export const alternaleafTheme = extendTheme({
  cssVarPrefix: 'legacy-alternaleafTheme',
  spacing: (factor: number) => `${0.25 * factor}rem`,
  typography: {
    fontSize: 16,
    htmlFontSize: 15,
    fontFamily: '"Mier B","Roboto","Helvetica","Arial",sans-serif',
    fontWeightBold: 800,
    button: {
      textTransform: 'none',
      fontSize: '1rem'
    },
    h4: {
      fontSize: 32
    },
    body1: {
      fontSize: 16
    },
    body2: {
      fontSize: 14
    }
  },
  breakpoints: {
    values: {
      xs: 0,
      sm: 640,
      md: 768,
      lg: 1024,
      xl: 1280
    }
  },
  colorSchemes: {
    light: alternaleafPalette,
    dark: alternaleafPalette
  },
  components: {
    MuiButton: {
      defaultProps: {
        disableRipple: true,
        disableElevation: true,
        disableFocusRipple: true,
        variant: 'contained'
      },
      styleOverrides: {
        root: ({ ownerState, theme: t }) => ({
          fontSize: '1.1rem',
          fontWeight: 'bold',
          letterSpacing: '0.0625rem',
          padding: '0.5rem 1.38rem',
          ...(ownerState.variant === 'outlined' &&
            ownerState.color === 'primary' && {
              borderColor: t.palette.primary.main
            }),
          ...(ownerState.variant === 'outlined' &&
            ownerState.color === 'secondary' && {
              borderColor: t.palette.secondary.main
            }),
          ...(ownerState.size === 'small' && {
            fontSize: '1rem' // TODO: Confirm with the design team
          })
        })
      }
    },
    MuiCard: {
      styleOverrides: {
        root: {
          borderRadius: '0.325rem'
        }
      }
    },
    MuiTooltip: {
      styleOverrides: {
        tooltip: {
          fontSize: '1.1rem'
        }
      }
    },
    MuiTableHead: {
      styleOverrides: {
        root: ({ theme: t }) => ({
          background: t.palette.grey[50],
          '& th': {
            color: t.palette.grey[700]
          }
        })
      }
    },
    MuiTableFooter: {
      styleOverrides: {
        root: ({ theme: t }) => ({
          background: t.palette.grey[50],
          color: t.palette.grey[700]
        })
      }
    },
    MuiTablePagination: {
      styleOverrides: {
        root: ({ theme: t }) => ({
          color: t.palette.grey[700],
          [`.${toolbarClasses.root}`]: {
            // Override mixin 90px
            height: 'unset'
          },
          '& p': {
            // Override global inherited rule from legacy scss
            marginBottom: 0
          }
        })
      }
    },
    MuiDialogContent: {
      styleOverrides: {
        root: {
          padding: '1rem'
        }
      }
    }
  },
  mixins: {
    toolbar: {
      height: '90px'
    }
  }
});

/** @deprecated - Use [theme.breakpoints.up('<breakpoint>')] within styled instead */
export const alternaleafMQ = (n: keyof typeof alternaleafTheme.breakpoints.values) =>
  `@media (min-width: ${theme.breakpoints.values[n]}px)`;

/**
 * The Material UI Sync plugin is generating theme properties with higher css specificity than the
 * `sx` prop styles.
 *
 * Use this only temporarily where required, so that we can easily track and remove this once we
 * figure out full solution.
 *
 * @example
 * ```ts
 * <Button sx={{ p: 99 }} />
 * ```
 *
 * Generates the `sx` customization for padding on the following selector:
 * ```
 * .css-1csjra-MuiButtonBase-root-MuiButton-root
 * ```
 *
 * But the theme overrides the `sx` padding by generating padding on the following selector:
 * ```
 * .css-1csjra-MuiButtonBase-root-MuiButton-root
 * .MuiButton-sizeLarge.MuiButton-containedPrimary
 * :not(.Mui-disabled)
 * :not(.Mui-focusVisible):not(:hover)
 * ```
 *
 * @deprecated - Use sparingly and question why we need to customize our components as often
 */
export const tmpSxSpecificityForce = '!important';

export default theme;

// This is a temp color list for the patient list page, apparently the full implementation is being worked on
export const tempPatientListColors = {
  active: {
    textColor: '#005B00',
    backgroundColor: '#E7FFE7'
  },
  inactive: {
    textColor: '#181B25DE',
    backgroundColor: '#181B250A'
  },
  pending: {
    textColor: '#00528B',
    backgroundColor: '#E6F4FF'
  },
  concession: {
    textColor: '#181B25DE',
    backgroundColor: '#E6EE9C'
  },
  discharged: {
    textColor: '#6C0000',
    backgroundColor: '#F9E6E6'
  },
  default: {
    textColor: '#181B25DE',
    backgroundColor: '#181B250A'
  }
};
