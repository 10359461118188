import AdminPageLayout from '@/components/layout/AdminPageLayout';
import { OrderActions } from '@/components/order/OrderActions/OrderActions';
import OrderDetailsReason from '@/components/order/orderDetailsReason/OrderDetailsReason';
import OrderFormButtons from '@/components/order/OrderFormButtons';
import OrderNote from '@/components/order/orderNote/OrderNote';
import { PatientOrderDetails } from '@/components/order/PatientOrderDetails/PatientOrderDetails';
import { OrderMetaProvider, useOrderFormMeta } from '@/context/order/OrderFormMeta';
import { Logger } from '@/utils/logger';
import { BrandThemeProvider, montu } from '@montugroup/themes';
import { Box, Stack, styled } from '@mui/material';
import { useNavigate } from 'react-router-dom';

const logger = new Logger('Order');

// Temporary - once montu theme has been updated to handle readOnly textfields/selects this should be removed
const Container = styled(Box)(() => ({
  '& .Mui-readOnly': {
    backgroundColor: `var(--montu-palette-_components-input-filled-enabledFill)`
  }
}));

export function Order() {
  const navigate = useNavigate();
  const { error } = useOrderFormMeta();

  if (error) {
    // todo - awaiting UX input for error state
    logger.error('Failed to load order', error);
    navigate('/404');
  }

  return (
    <BrandThemeProvider theme={montu}>
      <OrderMetaProvider>
        <AdminPageLayout title="Order" Actions={<OrderActions />} fullWidth={false}>
          <Container>
            <Stack gap={6}>
              <PatientOrderDetails />
              <OrderDetailsReason />
              <OrderNote />
              <OrderFormButtons />
            </Stack>
          </Container>
        </AdminPageLayout>
      </OrderMetaProvider>
    </BrandThemeProvider>
  );
}
