import { ToastConfirmModal, toastOptions } from '@/components/common/toastConfirm';
import { AdminPageSection } from '@/components/layout/AdminPageSection';
import settings from '@/constants/constants';
import USER_ROLES from '@/constants/userRoles';
import { useGetPatient } from '@/hooks/admin/useGetPatient';
import useDownload from '@/hooks/useDownload';
import useUser from '@/hooks/user/useUser';
import type { PatientData } from '@/services/patient.service';
import { Logger } from '@/utils/logger';
import type { ArrayElement } from '@/utils/typscript-helpers';
import { Button, PaginationVariant, Table, toast } from '@montugroup/design-system';
import Delete from '@mui/icons-material/Delete';
import FilePresent from '@mui/icons-material/FilePresent';
import FileUploadOutlinedIcon from '@mui/icons-material/FileUploadOutlined';
import { Box, Chip, Skeleton, Typography, styled } from '@mui/material';
import type { ColumnDef } from '@tanstack/react-table';
import axios from 'axios';
import { DateTime } from 'luxon';
import type { MouseEvent } from 'react';
import { useCallback, useMemo } from 'react';
import { Link, useNavigate, useParams } from 'react-router-dom';

type SASIngredientsDocument = ArrayElement<PatientData['SASIngredients']> & { type: 'AP' };
type SASDocument = ArrayElement<PatientData['sas']> & { type: 'SAS' };

type ApprovalDocument = SASDocument | SASIngredientsDocument;

function isAPDocumentType(obj: ApprovalDocument): obj is SASIngredientsDocument {
  return (obj as SASIngredientsDocument).sas_ingredient_path !== undefined;
}

const logger = new Logger('patientApprovals');
const PAGE_SIZE = 5;

const TruncatedLink = styled(Link)({
  overflow: 'hidden',
  textOverflow: 'ellipsis',
  whiteSpace: 'nowrap',
  textDecoration: 'underline'
});

const DocumentCellContainer = styled(Box)(({ theme }) => ({
  display: 'flex',
  justifyContent: 'start',
  alignItems: 'center',
  textAlign: 'center',
  gap: theme.spacing(2)
}));

const ActionCellContainer = styled(Box)({
  display: 'flex',
  justifyContent: 'center'
});

const NoDataFoundMessageContainer = styled(Box)(({ theme }) => ({
  display: 'flex',
  justifyContent: 'flex-start',
  margin: theme.spacing(4),
  // TODO: Remove negative margin after updating design-system table to use marginBottom rather than paddingBottom on the table root element
  marginBottom: `-${theme.spacing(2)}`
}));

const NoApprovalsFound = () => {
  return (
    <NoDataFoundMessageContainer>
      No approvals uploaded yet. Click &apos;Upload Approvals&apos; to add one.
    </NoDataFoundMessageContainer>
  );
};

export const PatientApprovals = () => {
  const { id } = useParams();
  const navigate = useNavigate();
  const { data: user } = useUser();
  const { data: { data: patientData } = {} } = useGetPatient(id ?? '');
  const { downloadFile } = useDownload();

  const isAdmin = Boolean(
    user?.roleId && [USER_ROLES.admin, USER_ROLES.ptxSupportPartner, USER_ROLES.superAdmin].includes(user.roleId)
  );

  const handleDownloadClick = useCallback(
    async (e: MouseEvent<HTMLAnchorElement>, sasDocument: ApprovalDocument) => {
      e.preventDefault();
      const oldFormat = !isAPDocumentType(sasDocument);

      if (!sasDocument.id || !sasDocument.patient_id) {
        toast.error('SAS document could not be downloaded.');
        logger.error('SAS document could not be downloaded. !sasDocument.id || !sasDocument.patient_id');
        return;
      }
      if (!oldFormat && !sasDocument.sas_ingredient_path) {
        toast.error('SAS document could not be downloaded.');
        logger.error('SAS document could not be downloaded. !sasDocument.sas_ingredient_path');
        return;
      }
      if (oldFormat && !sasDocument.sas_path) {
        toast.error('SAS document could not be downloaded.');
        logger.error('SAS document could not be downloaded. !sasDocument.sas_path');
        return;
      }

      const fileName = oldFormat ? sasDocument.sas_path : sasDocument.sas_ingredient_path;

      downloadFile(fileName, 'sas/document/download', {
        params: {
          sasId: sasDocument.id,
          patientId: sasDocument.patient_id,
          oldFormat
        }
      });
    },
    [downloadFile]
  );

  // TODO: This is currently broken prior to any refactoring
  const deletefile = useCallback(
    async (e: MouseEvent<SVGSVGElement>, sasDocument: ApprovalDocument) => {
      e.preventDefault();
      const value = sasDocument.id;
      const approvalType = isAPDocumentType(sasDocument) ? '' : 'old=true';

      toast(
        <ToastConfirmModal
          onConfirm={async () =>
            await axios
              .delete(settings.url + `/sas/delete?id=${value}&patientId=${id}&${approvalType}`)
              .then((response) => {
                if (response.data.status === 200) {
                  toast.success('SAS removed successfully');
                  navigate('/patients');
                } else {
                  toast.success('SAS could not be removed');
                }
              })
          }
        >
          <Typography>Are you sure you want to delete this?</Typography>
        </ToastConfirmModal>,
        toastOptions
      );
    },
    [id, navigate]
  );

  const approvals: Array<ApprovalDocument> =
    patientData?.SASIngredients.map(
      (ap): ApprovalDocument => ({
        ...ap,
        type: 'AP'
      })
    ).concat(patientData?.sas.map((sas): ApprovalDocument => ({ ...sas, type: 'SAS' }))) ?? [];

  const columns: ColumnDef<ApprovalDocument>[] = useMemo(
    () => [
      {
        accessorKey: 'id',
        header: 'id',
        enableSorting: true
      },
      {
        accessorKey: 'type',
        header: 'Type',
        enableSorting: true,
        meta: {
          style: { width: '4rem' }
        }
      },
      {
        // TODO: This cell is hidden for SAS until we have the required data
        accessorFn: (row) => (isAPDocumentType(row) ? row.ingredient_id : null),
        header: 'Category',
        enableSorting: true,
        meta: {
          style: { width: '5.375rem' }
        }
      },
      {
        // TODO: This cell is hidden for SAS until we have the required data
        accessorFn: (row) => (isAPDocumentType(row) ? row.ProductFormulation.name : null),
        header: 'Formulation',
        enableSorting: true,
        meta: {
          style: { width: '7.375rem' }
        }
      },
      {
        accessorFn: (row) => `${row.GeneralPractitioner.Doctor.first_name} ${row.GeneralPractitioner.Doctor.last_name}`,
        header: 'Doctor',
        enableSorting: true,
        meta: {
          style: { width: '6.25rem' }
        }
      },
      {
        header: 'Document',
        cell: ({ row: { original } }) => (
          <DocumentCellContainer>
            <FilePresent color="secondary" />
            <TruncatedLink to={''} onClick={(e) => handleDownloadClick(e, original)}>
              {isAPDocumentType(original) ? original.sas_ingredient_path : original.sas_path}
            </TruncatedLink>
          </DocumentCellContainer>
        ),
        meta: {
          style: { width: '10.625rem' }
        }
      },
      {
        header: 'Approval Date',
        enableSorting: true,
        // TODO: This cell is hidden for SAS until we have the required data
        accessorFn: (row) =>
          isAPDocumentType(row) && row.approval_date
            ? DateTime.fromISO(row.approval_date).setZone('local').toFormat('dd-MMM-yyyy')
            : null,
        meta: {
          style: { width: '10.625rem' }
        }
      },
      {
        header: 'Expiry Date',
        enableSorting: true,
        accessorFn: (row) => DateTime.fromISO(row.expiry_date).setZone('local').toFormat('dd-MMM-yyyy'),
        meta: {
          style: { width: '9.375rem' }
        }
      },
      {
        // TODO: These colors need to be updated to match designs
        header: 'Status',
        enableSorting: true,

        cell: ({ row: { original } }) => {
          // TODO: This cell is hidden for SAS until we have the required data
          if (!isAPDocumentType(original)) {
            return null;
          }
          if (!original.approval_date) {
            return <Chip color="info" label="Pending" />;
          } else if (original.active) {
            return <Chip color="success" label="Active" />;
          }
          return <Chip color="default" label="Expired" />;
        },
        meta: {
          style: { width: '6.25rem' }
        }
      },
      {
        header: 'Actions',
        id: 'actions',
        cell: ({ row: { original } }) => {
          return (
            <ActionCellContainer>
              <Delete onClick={(e) => deletefile(e, original)} color="action" sx={{ cursor: 'pointer' }} />
            </ActionCellContainer>
          );
        },
        meta: {
          style: { width: '5rem' }
        }
      }
    ],
    [deletefile, handleDownloadClick]
  );

  const ApprovalActions = () => (
    <Button onClick={() => navigate(`/approval/${id}`)} size="large" endIcon={<FileUploadOutlinedIcon />}>
      Upload Approvals
    </Button>
  );

  if (!patientData) {
    return (
      <AdminPageSection title="SAS/AP Approvals" Actions={<Skeleton width={200} height={38} variant="rectangular" />}>
        <Skeleton width="100%" height={150} variant="rectangular" />
      </AdminPageSection>
    );
  }

  return (
    <AdminPageSection title="SAS/AP Approvals" Actions={<ApprovalActions />}>
      <Table
        isTableLayoutFixed={true}
        data={approvals}
        columns={columns}
        columnVisibility={{ id: false, actions: isAdmin }}
        showPagination={approvals.length > PAGE_SIZE}
        pageSize={PAGE_SIZE}
        paginationVariant={PaginationVariant.FIXED_PAGE_SIZE}
        hasRowBackgroundColor={false}
        noDataFoundMessage={<NoApprovalsFound />}
        elevation={0}
      />
    </AdminPageSection>
  );
};
