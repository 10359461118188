import useGetPatientForOrder from '@/hooks/order/useGetPatientForOrder';
import { useGetOrder } from '@/hooks/orders/useGetOrder';
import type { PatientForOrderDetails } from '@/services/order.service';
import type { Order } from '@/types';
import { noOp } from '@/utils/noOp';
import type { PropsWithChildren } from 'react';
import { createContext, useContext, useMemo, useState } from 'react';
import { FormProvider, useForm } from 'react-hook-form';
import { useParams } from 'react-router-dom';

type OrderMeta = {
  order: Order | undefined;
  patientOrder: PatientForOrderDetails | undefined;
  error: Error | null;
  isFetching: boolean;
  isEditing: boolean;
  setIsEditing: (isEditing: boolean) => void;
};

export const OrderMetaContext = createContext<OrderMeta>({
  order: undefined,
  patientOrder: undefined,
  error: null,
  isFetching: false,
  isEditing: false,
  setIsEditing: noOp
});

const getFormDefaultValue = (order: Order | undefined, patientOrder: PatientForOrderDetails | undefined) => {
  const patientDetails = order?.Patient ?? patientOrder?.patient;
  return {
    patient: {
      id: patientDetails?.id ?? null,
      code: patientDetails?.patient_code ?? '',
      name: patientDetails ? `${patientDetails.PatientUser.first_name} ${patientDetails.PatientUser.last_name}` : ''
    },
    priority: order?.priority ?? false,
    adminOrderCreationReasonId: order?.adminOrderCreationReasonId ?? null,
    paymentStatus: order?.payment_status ?? '-',
    shopifyOrderConfirmationNumber: order?.orderManagementShopify?.shopify_order_confirmation_number,
    consignmentId: order?.consignmentID,
    shipment: {
      trackingId: order?.shipment_tracking_id,
      carrier: order?.shipment_carrier
    },
    notes:
      order?.order_notes
        ?.split('|')
        .filter((note) => note.trim() !== '')
        .map((note: string, id: number) => ({
          id,
          note
        })) ?? []
  };
};

export function OrderMetaProvider(props: PropsWithChildren) {
  const { id, patientId } = useParams();
  const { data: order, isFetching: orderIsFetching, error: orderError } = useGetOrder({ id: id as `${number}` });
  const {
    data: patientOrder,
    isFetching: patientIsFetching,
    error: patientError
  } = useGetPatientForOrder(patientId ? parseInt(patientId, 10) : undefined);

  const [isEditing, setIsEditing] = useState(false);

  const formMethods = useForm({
    values: getFormDefaultValue(order, patientOrder)
  });

  const value = useMemo(
    () => ({
      order,
      patientOrder,
      isFetching: orderIsFetching || patientIsFetching,
      error: orderError ?? patientError,
      isEditing,
      setIsEditing
    }),
    [order, patientOrder, orderIsFetching, patientIsFetching, orderError, patientError, isEditing]
  );

  return (
    <OrderMetaContext.Provider value={value}>
      <FormProvider {...formMethods}>{props.children}</FormProvider>
    </OrderMetaContext.Provider>
  );
}

export const useOrderFormMeta = () => {
  const { id, refill, patientId } = useParams();
  const { order, patientOrder, error, isFetching, isEditing, setIsEditing } = useContext(OrderMetaContext);

  const toggleEditing = () => {
    setIsEditing(!isEditing);
  };

  return {
    order,
    patientOrder,
    error,
    isLoading: isFetching,
    isRefill: !!refill,
    isNewOrder: Boolean(!refill && patientId && !order?.patient_id),
    isExistingOrder: Boolean(!!id && !refill && !patientId && order?.patient_id),
    isAdminOrderInAuthorisedPaymentStatus: Boolean(
      order?.payment_status === 'AUTHORISED' && order?.payment_initiated_path === 'ADMIN_INITIATED'
    ),
    isAdminInititatedOrder: Boolean(order?.payment_initiated_path === 'ADMIN_INITIATED'),
    isEditing,
    toggleEditing
  };
};
