import { FilterList as FilterListIcon } from '@mui/icons-material';
import { Autocomplete, Checkbox, InputAdornment, styled, TextField } from '@mui/material';
import type { SyntheticEvent } from 'react';
import { useState } from 'react';

type FilterSelectProps<T extends { id: number; name: string }> = {
  options: T[];
  selected: T[];
  name: string;
  label: string;
  onChange: (selected: T[]) => void;
  placeholder?: string;
  searchText?: string;
  width?: string;
  tagLimit?: number;
};

const ListItem = styled('li')`
  line-height: '1.5';
`;

export function FilterSelect<T extends { id: number; name: string }>({
  options,
  selected,
  name,
  label,
  onChange,
  placeholder,
  searchText = 'Search',
  width = '25rem',
  tagLimit = 1
}: FilterSelectProps<T>) {
  const [isOpen, setIsOpen] = useState(false);
  const handleChange = (_: SyntheticEvent, value: T[]) => {
    onChange(value);
  };

  const isOptionEqualToValue = (option: T, value: T) => {
    return option.id === value.id;
  };

  const getPlaceholder = () => {
    if (isOpen) {
      return searchText;
    }
    return selected.length === 0 ? placeholder : '';
  };

  return (
    <Autocomplete
      multiple
      size="small"
      id={`filter-select-${name}`}
      value={selected}
      onChange={handleChange}
      options={options}
      getOptionLabel={({ name }) => name}
      getOptionKey={({ id }) => id}
      isOptionEqualToValue={isOptionEqualToValue}
      limitTags={tagLimit}
      onOpen={() => setIsOpen(true)}
      onClose={() => setIsOpen(false)}
      renderOption={(props, option) => (
        <ListItem {...props} key={option.id}>
          <Checkbox checked={!!props['aria-selected']} />
          {option.name}
        </ListItem>
      )}
      renderInput={(params) => (
        <TextField
          {...params}
          sx={{ width }}
          variant="outlined"
          label={label}
          placeholder={getPlaceholder()}
          InputProps={{
            ...params.InputProps,
            startAdornment: (
              <>
                <InputAdornment position="start">
                  <FilterListIcon />
                </InputAdornment>
                {params.InputProps.startAdornment}
              </>
            )
          }}
        />
      )}
    />
  );
}
